import {
  delay,
  filter,
  ignoreElements,
  mapTo,
  map,
  of,
  exhaustMap,
  mergeMap,
  catchError,
  tap,
} from "rxjs/operators";
import {
  DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY,
  DEAL_SEARCH_FETCH_DETAIL_REQUEST,
  DEAL_SEARCH_FETCH_DETAIL_SUCCESS,
  DEAL_SEARCH_SHOW_SPLIT_VIEW,
  DealSearchAction,
} from "../actions/searchActions/DealSearchAction";
import { MatchingDealApi } from "../apis/MatchingDealApi";
import { combineEpics } from "redux-observable";

const dealSearchShowSplitViewEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === DEAL_SEARCH_SHOW_SPLIT_VIEW),
    map((action) => DealSearchAction.fetchDetail.request(action))
  );

const dealSearchFetchDetailRequestEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === DEAL_SEARCH_FETCH_DETAIL_REQUEST),
    exhaustMap((action) =>
      MatchingDealApi.showDetail({
        matchingDealUid: action.matchingDealUid,
        title: action.title,
      }).pipe(
        map(({ data }) => DealSearchAction.fetchDetail.success(data)),
        catchError((error) => DealSearchAction.fetchDetail.failure(error))
      )
    )
  );

const dealSearchFetchDetailSuccessEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === DEAL_SEARCH_FETCH_DETAIL_SUCCESS),
    map((action) => DealSearchAction.dealSearchChangeSplitViewBody(action))
  );

const dealSearchChangeBodyEpic = (action$) =>
  action$.pipe(
    filter((action) => action.type === DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY),
    delay(10),
    tap(() => $(".ds-detail-body pre").scrollTop(0)),
    ignoreElements()
  );

export const dealSearchEpic = combineEpics(
  dealSearchShowSplitViewEpic,
  dealSearchFetchDetailRequestEpic,
  dealSearchFetchDetailSuccessEpic,
  dealSearchChangeBodyEpic
);
