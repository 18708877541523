import React from "react";

export const DEAL_SEARCH_SCREEN_DETAIL_FORM_OPEN = "DEAL_SEARCH_SCREEN_DETAIL_FORM_OPEN";
export const DEAL_SEARCH_SCREEN_DETAIL_FORM_CLOSE = "DEAL_SEARCH_SCREEN_DETAIL_FORM_CLOSE";

export const DEAL_SEARCH_SELECT_AREA_ADD = "DEAL_SEARCH_SELECT_AREA_ADD";
export const DEAL_SEARCH_SELECT_AREA_REMOVE = "DEAL_SEARCH_SELECT_AREA_REMOVE";
export const DEAL_SEARCH_SELECT_AREA_REMOVE_ALL = "DEAL_SEARCH_SELECT_AREA_REMOVE_ALL";

export const DEAL_SEARCH_SELECT_INDUSTRY_ADD = "DEAL_SEARCH_SELECT_INDUSTRY_ADD";
export const DEAL_SEARCH_SELECT_INDUSTRY_REMOVE = "DEAL_SEARCH_SELECT_INDUSTRY_REMOVE";
export const DEAL_SEARCH_SELECT_INDUSTRY_REMOVE_ALL = "DEAL_SEARCH_SELECT_INDUSTRY_REMOVE_ALL";

export const DEAL_SEARCH_SELECT_BUDGET_ADD = "DEAL_SEARCH_SELECT_BUDGET_ADD";
export const DEAL_SEARCH_SELECT_BUDGET_REMOVE = "DEAL_SEARCH_SELECT_BUDGET_REMOVE";
export const DEAL_SEARCH_SELECT_BUDGET_REMOVE_ALL = "DEAL_SEARCH_SELECT_BUDGET_REMOVE_ALL";

const FormType = {
    Area: "Area",
    Industry: "Industry",
    Budget: "Budget",
};

const open = ({formType}) => ({
    type: DEAL_SEARCH_SCREEN_DETAIL_FORM_OPEN,
    formType
});

const close = props => ({
    type: DEAL_SEARCH_SCREEN_DETAIL_FORM_CLOSE,
});

const selectAreaAdd = ({area}) => ({
    type: DEAL_SEARCH_SELECT_AREA_ADD,
    area,
});

const selectAreaRemove = ({area}) => ({
    type: DEAL_SEARCH_SELECT_AREA_REMOVE,
    area,
});

const selectAreaRemoveAll = props => ({
    type: DEAL_SEARCH_SELECT_AREA_REMOVE_ALL,
});

const selectIndustryAdd = ({industry}) => ({
    type: DEAL_SEARCH_SELECT_INDUSTRY_ADD,
    industry,
});

const selectIndustryRemove = ({industry}) => ({
    type: DEAL_SEARCH_SELECT_INDUSTRY_REMOVE,
    industry,
});

const selectIndustryRemoveAll = props => ({
    type: DEAL_SEARCH_SELECT_INDUSTRY_REMOVE_ALL,
});


const selectBudgetAdd = ({budget}) => ({
    type: DEAL_SEARCH_SELECT_BUDGET_ADD,
    budget,
});

const selectBudgetRemove = ({budget}) => ({
    type: DEAL_SEARCH_SELECT_BUDGET_REMOVE,
    budget,
});

const selectBudgetRemoveAll = props => ({
    type: DEAL_SEARCH_SELECT_BUDGET_REMOVE_ALL,
});


export const DealSearchScreenDetailFormAction = {
    open,
    close,
    FormType,
    selectArea: {
        add: selectAreaAdd,
        remove: selectAreaRemove,
        removeAll: selectAreaRemoveAll,
    },
    selectIndustry: {
        add: selectIndustryAdd,
        remove: selectIndustryRemove,
        removeAll: selectIndustryRemoveAll,
    },
    selectBudget: {
        add: selectBudgetAdd,
        remove: selectBudgetRemove,
        removeAll: selectBudgetRemoveAll,
    },
};
