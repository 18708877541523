import React from "react";
import is from "is_js";
import { DealSearchAction } from "../../actions/searchActions/DealSearchAction";
import { RootStore } from "../../stores/searchStore";
import { dealSearchCurrentItemSelector } from "../../selectors/search/dealSearchShowSplitSelector";
import { connect } from "react-redux";
import * as classNames from "classnames";
import { titleForUrl } from "../../utils/titleForUrl";
import { sendGcEvent } from "../../utils/sendGcEvent";
import { dealSearchFeedItemPropTypes } from "../../propTypes/dealSearchFeedItemPropTypes";

const MEDIUM_DEVICE_SIZE = 767;

class DealSearchFeedItem extends React.Component {
  constructor(props) {
    super(props);
    this.click = this.click.bind(this);
    this.sendGaEvent = this.sendGaEvent.bind(this);
    this.isSell = this.isSell.bind(this);
    this.isBuy = this.isBuy.bind(this);
    this.isAdviser = this.isAdviser.bind(this);
    this.isSellBuy = this.isSellBuy.bind(this);
    this.feedRenderDefault = this.feedRenderDefault.bind(this);
    this.feedRenderProfessional = this.feedRenderProfessional.bind(this);
  }

  /**
   * 通常ユーザーのフィードデザイン
   * @returns {*}
   */
  feedRenderDefault() {
    const {
      company,
      title,
      url,
      currentItem,
      matchingDealUid,
      industries,
      socLabel,
      socLabel2,
      profitLabel,
      profitLabel2,
      dobLabel,
      profitValue,
      socValue,
      dobValue,
      status,
      areas,
      business,
      numberOfEmployees,
      numberOfUsers,
      foundingYear,
      pv,
      publicationAt,
    } = this.props;

    const isNew =
      Number(new Date()) - Number(new Date(publicationAt)) < 604800016.56;

    const truncatedBusiness = business ? business.slice(0, 50) : "";
    const isTruncate = business
      ? business.length
      : 0 !== truncatedBusiness.length;

    const isActive = currentItem === matchingDealUid;
    return (
      <article
        key={`ds-item--${matchingDealUid}`}
        className={classNames(
          "ds-item p-sm-1 pl-sm-2",
          isActive ? "active" : ""
        )}
        onClick={this.click}
      >
        <section>
          <a target="_blank" className={"search-title"} href={url}>
            {this.isAdviser() ? (
              <h1 className={"ds-item-title search-title"} onClick={this.click}>
                <div className="fs-18">{company}</div>
                <div className="fs-13 gray mt-1">{this.fixTitle(title)}</div>
              </h1>
            ) : (
              <h1
                className={"fs-18 ds-item-title search-title"}
                onClick={this.click}
              >
                {this.fixTitle(title)}
              </h1>
            )}
          </a>
        </section>
        {this.isSellBuy() ? (
          <section>
            <ul className="ds-value-list">
              {industries.map((industry) => (
                <li key={`industry-${matchingDealUid}-${industry}`}>
                  {industry}
                </li>
              ))}
              <li>
                {socLabel}
                {socLabel2}&ensp;
                <span dangerouslySetInnerHTML={{ __html: socValue }} />
              </li>
              <li>
                {profitLabel}
                {profitLabel2}&ensp;
                <span dangerouslySetInnerHTML={{ __html: profitValue }} />
              </li>
              <li>
                {dobLabel}&ensp;
                <span dangerouslySetInnerHTML={{ __html: dobValue }} />
              </li>
              {numberOfEmployees ? (
                <li>
                  従業員数&emsp;
                  <span
                    dangerouslySetInnerHTML={{ __html: numberOfEmployees }}
                  />
                </li>
              ) : null}
              {numberOfUsers ? (
                <li>
                  ユーザー数&emsp;
                  <span dangerouslySetInnerHTML={{ __html: numberOfUsers }} />
                </li>
              ) : null}
              {foundingYear ? (
                <li>
                  創業&emsp;
                  <span dangerouslySetInnerHTML={{ __html: foundingYear }} />
                </li>
              ) : null}
              {pv ? (
                <li>
                  <span dangerouslySetInnerHTML={{ __html: pv }} />
                </li>
              ) : null}
            </ul>
          </section>
        ) : null}
        <section>
          <ul className="ds-tag-list">
            {isNew ? (
              <li>
                <label
                  className="badge badge-outline new"
                  style={{ verticalAlign: "text-bottom" }}
                >
                  新着
                </label>
              </li>
            ) : null}
            {areas.map((area) => (
              <li key={`area-${matchingDealUid}-${area}`}>
                <label
                  className="badge badge-outline"
                  style={{ verticalAlign: "text-bottom" }}
                >
                  {area}
                </label>
              </li>
            ))}
            {this.isAdviser()
              ? industries.map((industry) => (
                  <li key={`industry-${matchingDealUid}-${industry}`}>
                    <label
                      className="badge badge-outline"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      {industry}
                    </label>
                  </li>
                ))
              : null}
            {this.isSellBuy() ? (
              <li>
                <label
                  className="badge badge-outline "
                  style={{ verticalAlign: "text-bottom" }}
                >
                  {status}
                </label>
              </li>
            ) : null}
          </ul>
        </section>
        <section>
          <p className="fs-14 gray ds-item-business">
            {truncatedBusiness}
            {isTruncate ? "..." : ""}
          </p>
        </section>
        <section className="ds-cite">
          {this.isSellBuy() ? (
            <span className="mr-1">{publicationAt}</span>
          ) : null}
          <cite className="mr-1">{url}</cite>
        </section>
        <hr className="d-md-none ds-sm-item-separator" />
      </article>
    );
  }

  /**
   * アドバイザー登録した人用のフィードデザイン
   */
  feedRenderProfessional() {
    const {
      company,
      title,
      url,
      currentItem,
      matchingDealUid,
      industries,
      areas,
      business,
      catchCopy,
      imageFace,
      services,
      noFaceImagePath,
    } = this.props;

    const truncatedBusiness = business ? business.slice(0, 100) : "";
    const isTruncate =
      (business ? business.length : 0) !== truncatedBusiness.length;

    const isActive = currentItem === matchingDealUid;
    return (
      <article
        key={`ds-item--${matchingDealUid}`}
        className={classNames(
          "ds-item ds-item__professional p-sm-1 pl-sm-2",
          isActive ? "active" : ""
        )}
        onClick={this.click}
      >
        <section className="catch-copy-container">
          <div className="catch-copy">{catchCopy}</div>
        </section>

        <div className="row">
          <div className="col-3 no-padding-right image-face-column">
            <section>
              <div className="image-face-container">
                <img
                  src={imageFace ? imageFace : noFaceImagePath}
                  alt={title}
                />
              </div>
            </section>
          </div>
          <div className="col">
            <section>
              <div className="row mb-1">
                <div className="col">
                  <a target="_blank" className={"search-title"} href={url}>
                    <h1
                      className={"ds-item-title search-title"}
                      onClick={this.click}
                    >
                      <div className="fs-24 title">{title}</div>
                      <div className="fs-13 gray mt-1 company">{company}</div>
                    </h1>
                  </a>
                </div>
              </div>
            </section>
            <section className="description-container">
              <ul className="ds-tag-list">
                {areas.slice(0, 1).map((area) => (
                  <li key={`area-${matchingDealUid}-${area}`}>
                    <label
                      className="badge badge-outline area"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      {area}
                    </label>
                  </li>
                ))}
                {services.slice(0, 3).map((service) => (
                  <li key={`service-${matchingDealUid}-${service}`}>
                    <label
                      className="badge badge-outline service"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      {service}
                    </label>
                  </li>
                ))}
                {industries.slice(0, 3).map((industry) => (
                  <li key={`industry-${matchingDealUid}-${industry}`}>
                    <label
                      className="badge badge-outline industry"
                      style={{ verticalAlign: "text-bottom" }}
                    >
                      {industry}
                    </label>
                  </li>
                ))}
              </ul>
              <p className="fs-11 gray ds-item-business">
                {truncatedBusiness}
                {isTruncate ? "..." : ""}
              </p>
            </section>
          </div>
        </div>
      </article>
    );
  }

  render() {
    if (this.isAdviser() && this.props.hasProfile) {
      // 掲載プロフィールを持っている
      return this.feedRenderProfessional();
    } else {
      // 通常
      return this.feedRenderDefault();
    }
  }

  /**
   * 案件をクリック
   * @param event
   */
  click(event) {
    const { hasProfile, url } = this.props;
    this.sendGaEvent();
    const $window = $(window);

    if (this.isAdviser()) {
      // アドバイザーページ
      if (hasProfile) {
        // プロフィール掲載有り
        if (event.ctrlKey || event.metaKey) {
          window.open(url, "_blank");
        } else {
          location.href = url;
        }
        event.preventDefault();
      } else if (
        location.href.indexOf("/area/") !== -1 ||
        location.href.indexOf("/industry/") !== -1
      ) {
        // クッションページを表示する（多段階層ページ）
        const { matchingDealUid, title, mode } = this.props;
        const url = `../../${mode}s/item/${this.titleForUrl(
          this.fixTitle(title)
        )}/${matchingDealUid}`;
        if (event.ctrlKey || event.metaKey) {
          window.open(url, "_blank");
        } else {
          location.href = url;
        }
        event.preventDefault();
      } else {
        // クッションページを表示する（階層ページ）
        const { matchingDealUid, title, mode } = this.props;
        const url = `../${mode}s/item/${this.titleForUrl(
          this.fixTitle(title)
        )}/${matchingDealUid}`;
        if (event.ctrlKey || event.metaKey) {
          window.open(url, "_blank");
        } else {
          location.href = url;
        }
        event.preventDefault();
      }
    } else {
      // アドバイザーページ以外
      if (location.pathname === "/") {
        // ルートページは直接開く
      } else if ($window.width() > MEDIUM_DEVICE_SIZE) {
        const { matchingDealUid } = this.props;
        // サイドビューで開く
        event.preventDefault();
        RootStore.dispatch(
          DealSearchAction.dealSearchShowSplitView({ ...this.props })
        );
        RootStore.dispatch(
          DealSearchAction.dealSearchUpdateCurrentItem({ matchingDealUid })
        );
        // サイドバー調整のためスクロールイベントを発火
        if (!is.mobile()) {
          $(window).trigger("scroll");
        }
      } else if (is.mobile() && hasProfile) {
        // モバイルでプロフィール掲載有り
        location.href = url;
        event.preventDefault();
      } else if (
        is.mobile() &&
        (location.href.indexOf("/area/") !== -1 ||
          location.href.indexOf("/industry/") !== -1)
      ) {
        // モバイルでクッションページを表示する（多段階層ページ）
        const { matchingDealUid, title, mode } = this.props;
        location.href = `../../${mode}s/item/${this.titleForUrl(
          this.fixTitle(title)
        )}/${matchingDealUid}`;
        event.preventDefault();
      } else if (is.mobile()) {
        // モバイルでクッションページを表示する
        const { matchingDealUid, title, mode } = this.props;
        location.href = `./${mode}s/item/${this.titleForUrl(
          this.fixTitle(title)
        )}/${matchingDealUid}`;
        event.preventDefault();
      } else {
        // 直接開く
      }
    }
  }

  /**
   * 案件を開いたイベントを記録
   */
  sendGaEvent() {
    const { industries, areas, mode } = this.props;

    switch (mode) {
      case "sell":
        industries.forEach((industry) => sendGcEvent("dimension1", industry));
        areas.forEach((area) => sendGcEvent("dimension2", area));
        break;
      case "buy":
        industries.forEach((industry) => sendGcEvent("dimension5", industry));
        areas.forEach((area) => sendGcEvent("dimension6", area));
        break;
      case "adviser":
        break;
      default:
        throw new Error(`unknown mode type: ${mode}`);
    }
  }

  /**
   * タイトルが無題の場合の対応
   */
  fixTitle(title) {
    const trimmedTitle = title.toString().trim();
    return trimmedTitle
      ? trimmedTitle
      : this.isAdviser()
      ? "（名前なし）"
      : "（案件名なし）";
  }

  /**
   * titleをURLで使用できる形をとる
   */
  titleForUrl(title) {
    return titleForUrl(title);
  }

  /**
   * 売り・買い案件の場合
   */
  isSellBuy() {
    return this.isSell() || this.isBuy();
  }

  /**
   * 売り案件の場合
   */
  isSell() {
    const { mode } = this.props;
    return mode === "sell";
  }

  /**
   * 買い案件の場合
   */
  isBuy() {
    const { mode } = this.props;
    return mode === "buy";
  }

  /**
   * アドバイザーの場合
   */
  isAdviser() {
    const { mode } = this.props;
    return mode === "adviser";
  }
}

DealSearchFeedItem.propTypes = dealSearchFeedItemPropTypes;

const mapDispatchToProps = {};
export default connect(
  dealSearchCurrentItemSelector,
  mapDispatchToProps
)(DealSearchFeedItem);
