import {createStructuredSelector} from "reselect";

export const dealSearchShowSplitSelector = createStructuredSelector({
  company: state => state.dealSearch.dealSearchShowSplitView.company,
  title: state => state.dealSearch.dealSearchShowSplitView.title,
  url: state => state.dealSearch.dealSearchShowSplitView.url,
  matchingDealUid: state => state.dealSearch.dealSearchShowSplitView.matchingDealUid,
  body: state => state.dealSearch.dealSearchShowSplitView.body,
  industries: state => state.dealSearch.dealSearchShowSplitView.industries,
  socLabel: state => state.dealSearch.dealSearchShowSplitView.socLabel,
  socLabel2: state => state.dealSearch.dealSearchShowSplitView.socLabel2,
  profitLabel: state => state.dealSearch.dealSearchShowSplitView.profitLabel,
  profitLabel2: state => state.dealSearch.dealSearchShowSplitView.profitLabel2,
  dobLabel: state => state.dealSearch.dealSearchShowSplitView.dobLabel,
  profitValue: state => state.dealSearch.dealSearchShowSplitView.profitValue,
  socValue: state => state.dealSearch.dealSearchShowSplitView.socValue,
  dobValue: state => state.dealSearch.dealSearchShowSplitView.dobValue,
  thumbnailImagePath: state => state.dealSearch.dealSearchShowSplitView.thumbnailImagePath,
  status: state => state.dealSearch.dealSearchShowSplitView.status,
  areas: state => state.dealSearch.dealSearchShowSplitView.areas,
  business: state => state.dealSearch.dealSearchShowSplitView.business,
  numberOfEmployees: state => state.dealSearch.dealSearchShowSplitView.numberOfEmployees,
  numberOfUsers: state => state.dealSearch.dealSearchShowSplitView.numberOfUsers,
  foundingYear: state => state.dealSearch.dealSearchShowSplitView.foundingYear,
  pv: state => state.dealSearch.dealSearchShowSplitView.pv,
  publicationAt: state => state.dealSearch.dealSearchShowSplitView.publicationAt,
  currentItem: state => state.dealSearch.currentItem,
  mode: state => state.dealSearch.mode,
  profitSuffix: state => state.dealSearch.dealSearchShowSplitView.profitSuffix,
  socSuffix: state => state.dealSearch.dealSearchShowSplitView.socSuffix,
  dobSuffix: state => state.dealSearch.dealSearchShowSplitView.dobSuffix,
  hasProfile: state => state.dealSearch.dealSearchShowSplitView.hasProfile,
  catchCopy: state => state.dealSearch.dealSearchShowSplitView.catchCopy,
  imageFace: state => state.dealSearch.dealSearchShowSplitView.imageFace,
  imageCompany: state => state.dealSearch.dealSearchShowSplitView.imageCompany,
  services: state => state.dealSearch.dealSearchShowSplitView.services,
  noFaceImagePath: state => state.dealSearch.dealSearchShowSplitView.noFaceImagePath,
});

export const dealSearchCurrentItemSelector = createStructuredSelector({
  currentItem: state => state.dealSearch.currentItem
});
