import * as PropTypes from "prop-types"
export const dealSearchFeedItemPropTypes = {
  company: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  matchingDealUid: PropTypes.number,
  industries: PropTypes.array,
  socLabel: PropTypes.string,
  socLabel2: PropTypes.string,
  profitLabel: PropTypes.string,
  profitLabel2: PropTypes.string,
  dobLabel: PropTypes.string,
  profitValue: PropTypes.string,
  socValue: PropTypes.string,
  dobValue: PropTypes.string,
  thumbnailImagePath: PropTypes.string,
  status: PropTypes.string,
  areas: PropTypes.array,
  business: PropTypes.string,
  numberOfEmployees: PropTypes.string,
  numberOfUsers: PropTypes.string,
  foundingYear: PropTypes.string,
  pv: PropTypes.string,
  publicationAt: PropTypes.string,
  mode: PropTypes.node,
  profitSuffix: PropTypes.number,
  socSuffix: PropTypes.number,
  dobSuffix: PropTypes.number,
  hasProfile: PropTypes.bool,
  catchCopy: PropTypes.string,
  imageFace: PropTypes.string,
  imageCompany: PropTypes.string,
  services: PropTypes.array,
  noFaceImagePath: PropTypes.string,
  recommendAdvisers: PropTypes.array,
};
