import React from "react"
import {hot} from 'react-hot-loader'
import AreaTable from "./AreaTable";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import DealSearchSelectSearchTypeModal from "./DealSearchSelectSearchTypeModal";
import * as PropTypes from "prop-types";

am4core.useTheme(am4themes_animated);


/**
 * エリアから検索
 */
class DealSearchJapanAreaMap extends React.Component {

    mapOffset = {
        x: -38,
        y: 0,
    };

    state = {
        chart: null,
        polygonSeries: [],
        polygonTemplate: null,
        activeState: null,
        currentPrefecture: null,
    };

    constructor(props) {
        super(props);
        this.renderJapanMap = this.renderJapanMap.bind(this);
        this.setPrefectureData = this.setPrefectureData.bind(this);
        this.onClickPrefecture = this.onClickPrefecture.bind(this);
        this.onClickCloseButton = this.onClickCloseButton.bind(this);
    }

    componentDidMount() {
        this.renderJapanMap();
    }

    componentWillUnmount() {
        if (this.state.chart) {
            this.state.chart.dispose();
        }
    }

    /**
     * 日本地図を描画
     */
    renderJapanMap() {
        this.state.chart = am4core.create("japanMap", am4maps.MapChart);
        this.state.polygonSeries = this.state.chart.series.push(new am4maps.MapPolygonSeries());
        // noinspection JSUnresolvedVariable
        this.state.chart.geodataSource.url = "/jsons/japanLow.json";
        // noinspection JSUnresolvedVariable
        this.state.chart.geodataSource.events.on("parseended", this.setPrefectureData);
        this.state.chart.projection = new am4maps.projections.Mercator();
        this.state.chart.zoomControl = new am4maps.ZoomControl();
        this.state.chart.zoomControl.disabled = true; // ズームコントロールの禁止
        this.state.chart.draggable = false; // ドラッグの禁止
        this.state.chart.focusable = false; // フォーカスの禁止

        // 地図の位置
        this.state.chart.homeZoomLevel = 1;
        this.state.chart.minPanOut = 1;
        this.state.chart.maxPanOut = 1;
        this.state.chart.minZoomLevel = 1;
        this.state.chart.maxZoomLevel = 1;
        this.state.chart.minZoomCount = 1;
        this.state.chart.maxZoomCount = 1;
        this.state.chart.x = -50 + this.mapOffset.x;
        this.state.chart.y = this.mapOffset.y;

        // 位置を指定
        // this.state.chart.homeGeoPoint = {
        //     latitude: 35.6804,
        //     longitude: 139.7690
        // };

        this.state.polygonSeries.focusable = false; // フォーカスの禁止

        // ダブルクリックイベントを無効
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction
        this.state.chart.seriesContainer.events.disableType("doublehit");
        // noinspection JSUnresolvedVariable,JSUnresolvedFunction
        this.state.chart.chartContainer.background.events.disableType("doublehit");

        // noinspection JSUnresolvedVariable
        this.state.chart.seriesContainer.draggable = false; // ドラッグの禁止
        // noinspection JSUnresolvedVariable
        this.state.chart.seriesContainer.resizable = false; // リサイズの禁止
        // noinspection JSUnresolvedVariable
        this.state.chart.mouseWheelBehavior = "none"; // スクロールイベントの禁止

        // noinspection JSUnresolvedVariable
        this.state.polygonSeries.mapPolygons.template.strokeWidth = 0.5;
        this.state.polygonSeries.useGeodata = true;
        // noinspection JSUnresolvedVariable
        this.state.polygonTemplate = this.state.polygonSeries.mapPolygons.template;
        this.state.polygonTemplate.tooltipText = "{name}"; // "{name}: {value}"

        this.state.polygonTemplate.fill = am4core.color("#005395"); // 地図の色
        const hs = this.state.polygonTemplate.states.create("hover");
        hs.properties.fill = this.state.chart.colors.getIndex(0);
        this.state.activeState = this.state.polygonTemplate.states.create("active");
        this.state.activeState.properties.fill = this.state.chart.colors.getIndex(1);
        this.state.polygonTemplate.events.on("hit", this.onClickPrefecture);
    }

    /**
     * 都道府県のデータをセット
     * @param event
     */
    setPrefectureData(event) {
        let data = [];
        for (let i = 0; i < event.target.data.features.length; i++) {
            data.push({
                id: event.target.data.features[i].id,
                value: 0
            })
        }
        this.state.polygonSeries.data = data;
    }

    /**
     * 都道府県をクリック
     * @param event
     * @param item
     */
    onClickPrefecture(event, item) {
        // noinspection JSUnresolvedVariable
        const prefecture = item ? item : event.target.tooltipDataItem.dataContext.name;
        this.setState({currentPrefecture: prefecture});
        this.setPrefecture(prefecture);
    }

    render() {

        const endpointPrefix = "/sells/area/";
        const {currentPrefecture} = this.state;


        return <div className="ds-japan-area-map-container">
            <h3 className="area-search__label">エリアから検索</h3>
            <div>
                <AreaTable title={"北海道"} items={["北海道"]} endpointPrefix={endpointPrefix} endpointSuffix={""}
                           left={this.mapOffset.x + 290}
                           top={this.mapOffset.y + 20}
                           onClick={this.onClickPrefecture}/>
                <AreaTable title={"甲信越"} items={["新潟県", "石川県", "福井県"]} endpointPrefix={endpointPrefix}
                           endpointSuffix={""}
                           left={this.mapOffset.x + 195} top={this.mapOffset.y + 112} onClick={this.onClickPrefecture}/>
                <AreaTable title={"北陸"} items={["富山県", "石川県", "福井県"]} endpointPrefix={endpointPrefix}
                           endpointSuffix={""} left={this.mapOffset.x + 195}
                           top={this.mapOffset.y + 211} onClick={this.onClickPrefecture}/>
                <AreaTable title={"中国"} items={["鳥取県", "島根県", "岡山県", "広島県", "山口県"]} endpointPrefix={endpointPrefix}
                           endpointSuffix={""} left={this.mapOffset.x + 70} top={this.mapOffset.y + 270}
                           onClick={this.onClickPrefecture}/>
                <AreaTable title={"九州・沖縄"} items={["福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "鹿児島県", "沖縄県"]}
                           endpointPrefix={endpointPrefix} endpointSuffix={""} left={this.mapOffset.x + 158}
                           top={this.mapOffset.y + 568}
                           onClick={this.onClickPrefecture}/>
                <AreaTable title={"四国"} items={["愛媛県", "香川県", "高知県", "徳島県"]} endpointPrefix={endpointPrefix}
                           endpointSuffix={""}
                           left={this.mapOffset.x + 220} top={this.mapOffset.y + 466} onClick={this.onClickPrefecture}/>
                <AreaTable title={"近畿"} items={["大阪府", "京都府", "兵庫県", "奈良県", "三重県", "滋賀県", "和歌山県"]}
                           endpointPrefix={endpointPrefix}
                           endpointSuffix={""} left={this.mapOffset.x + 350} top={this.mapOffset.y + 440}
                           onClick={this.onClickPrefecture}/>
                <AreaTable title={"東海"} items={["岐阜県", "愛知県", "静岡県"]} endpointPrefix={endpointPrefix}
                           endpointSuffix={""} left={this.mapOffset.x + 480}
                           top={this.mapOffset.y + 440} onClick={this.onClickPrefecture}/>
                <AreaTable title={"関東"} items={["東京都", "神奈川県", "千葉県", "埼玉県", "茨城県", "栃木県", "群馬県"]}
                           endpointPrefix={endpointPrefix}
                           endpointSuffix={""} left={this.mapOffset.x + 480} top={this.mapOffset.y + 280}
                           onClick={this.onClickPrefecture}/>
                <AreaTable title={"東北"} items={["青森県", "岩手県", "秋田県", "宮城県", "山形県", "福島県"]}
                           endpointPrefix={endpointPrefix}
                           endpointSuffix={""} left={this.mapOffset.x + 480} top={this.mapOffset.y + 150}
                           onClick={this.onClickPrefecture}/>
            </div>
            <div>
                <div id="japanMap" style={{width: "100%", height: "700px"}}/>
            </div>
            <div>
            </div>
            <div>
                <DealSearchSelectSearchTypeModal {...this.props} prefecture={currentPrefecture}
                                                 onClickCloseButton={this.onClickCloseButton}
                                                 getSetPrefectureFunc={setPrefecture => {
                                                     this.setPrefecture = setPrefecture;
                                                 }}
                />
            </div>
        </div>;
    }

    onClickCloseButton() {
        this.setState({currentPrefecture: null});
    }
}


DealSearchJapanAreaMap.propTypes = {
    logoSells: PropTypes.string.isRequired,
    logoBuys: PropTypes.string.isRequired,
    logoAdvisers: PropTypes.string.isRequired,
};

export default hot(module)(DealSearchJapanAreaMap);
