import React from "react"
import {hot} from 'react-hot-loader'
import * as PropTypes from "prop-types"

/**
 * エリア選択後に検索タイプを指定
 */
class DealSearchSelectSearchTypeModal extends React.Component {

    state = {
        industry: null,
        area: null,
        isNativeAccess: false,
        isShow: false,
        timer: null,
    };

    constructor(props) {
        super(props);
        const {isNativeAccess} = props;
        this.onClickCloseButton = this.onClickCloseButton.bind(this);
        this.showDealSearchSelectSearchTypeModal = this.showDealSearchSelectSearchTypeModal.bind(this);

        if (props.getSetPrefectureFunc) {
            props.getSetPrefectureFunc(this.setPrefecture.bind(this));
        }

        this.state.isNativeAccess = !!isNativeAccess;
        if (this.state.isNativeAccess) {
            window.showDealSearchSelectSearchTypeModal = this.showDealSearchSelectSearchTypeModal;
        }
    }

    render() {
        const {logoSells, logoBuys, logoAdvisers} = this.props;
        const {isNativeAccess, industry, area} = this.state;
        const name = industry || area;
        const model = industry ? "industry" : "area";

        return <article
            className={`react-component__deal-search-select-search-type-modal ${this.state.isShow ? "show" : "hide"}`}>
            <a onClick={this.onClickCloseButton}>
                <span className={"react-compnent__deal-search-select-search-type-modal__modal-close-area"}></span>
            </a>
            <div className="react-component__deal-search-select-search-type-modal__container">
                <div className={"react-component__deal-search-select-search-type-modal__inner-container"}>
                    <div className={"react-component__deal-search-select-search-type-modal__modal-header"}>
                        <h1><span className={"title"}>{name}</span> のM&A案件を検索する</h1>
                    </div>
                    <ul>
                        <li>
                            <h2>{name}の売り案件を探したい方</h2>
                            <a href={`/sells/${model}/${name}`}>
                                <div className={"btn-search-item"}>
                                    <img src={logoSells}/>
                                </div>
                            </a>
                        </li>
                        <li>
                            <h2>{name}のM&Aアドバイザーを探したい方</h2>
                            <a href={`/advisers/${model}/${name}`}>
                                <div className={"btn-search-item"}>
                                    <img src={logoAdvisers}/>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div className="react-component__deal-search-select-search-type-modal__close-button">
                        <a onClick={this.onClickCloseButton}>
                            <div className="line1"/>
                            <div className="line2"/>
                            <label className="label">閉じる</label>
                        </a>
                    </div>
                </div>
            </div>
        </article>;
    }

    showDealSearchSelectSearchTypeModal(props) {
        const {industry, area} = props;
        console.log(industry, area);
        this.setState({industry, area, isShow: true});
        clearTimeout(this.state.timer);
    }

    setPrefecture(area) {
        this.state.isShow = true;
        this.setState({area});
        clearTimeout(this.state.timer);
    }

    onClickCloseButton() {
        const {onClickCloseButton} = this.props;
        const {isNativeAccess} = this.state;

        this.setState({isShow: false});
        clearTimeout(this.state.timer);
        this.state.timer = setTimeout(() => {
            if (isNativeAccess) {
                this.setState({industry: null});
            } else {
                this.setState({area: null});
                onClickCloseButton && onClickCloseButton();
            }
        }, 1000);
    }
}


DealSearchSelectSearchTypeModal.propTypes = {
    area: PropTypes.string,
    logoSells: PropTypes.string.isRequired,
    logoBuys: PropTypes.string.isRequired,
    logoAdvisers: PropTypes.string.isRequired,
    getSetPrefectureFunc: PropTypes.func,
    setPrefecture: PropTypes.func,
    isNativeAccess: PropTypes.bool,
};

export default hot(module)(DealSearchSelectSearchTypeModal);
