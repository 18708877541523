import React from "react";
import { connect } from "react-redux";
import { dealSearchShowSplitSelector } from "../../selectors/search/dealSearchShowSplitSelector";
import { DealSearchAction } from "../../actions/searchActions/DealSearchAction";
import { createStructuredSelector } from "reselect";
import { currentUserSelector } from "../../selectors/currentUserSelector";
import { RootStore } from "../../stores/searchStore";
import { sendGcEvent } from "../../utils/sendGcEvent";
import { dealSearchFeedItemPropTypes } from "../../propTypes/dealSearchFeedItemPropTypes";

class DealSearchSplitView extends React.Component {
  headerRef = React.createRef();
  bodyRef = React.createRef();
  preRef = React.createRef();
  footerRef = React.createRef();

  constructor(props) {
    super(props);
    this.favoriteItem = this.favoriteItem.bind(this);
    this.sendGcEvent = this.sendGcEvent.bind(this);
  }

  componentDidUpdate() {
    const { mode } = this.props;

    if (
      this.isSellBuy(mode) &&
      this.headerRef &&
      this.headerRef.current &&
      this.bodyRef &&
      this.bodyRef.current &&
      this.preRef &&
      this.preRef.current &&
      this.footerRef &&
      this.footerRef.current
    ) {
      const height = this.headerRef.current.clientHeight;
      this.bodyRef.current.style.height = `calc( 100% - ${height + 110}px)`;
    }
  }

  render() {
    let {
      company,
      title,
      url,
      matchingDealUid,
      body,
      industries,
      socLabel,
      socLabel2,
      profitLabel,
      profitLabel2,
      dobLabel,
      profitValue,
      socValue,
      dobValue,
      thumbnailImagePath,
      status,
      areas,
      hasProfile,
      currentItem,
    } = this.props.dealSearch;
    const { mode, recommendAdvisers } = this.props;

    const { isLogged } = this.props.currentUser;

    const noSelect = null;
    return (
      <div
        className="ds-detail-container"
        id="ds-detail-container"
        style={{ display: currentItem === noSelect ? "none" : "inherit" }}
      >
        <article className={"ds-detail-article"}>
          <section className="ds-detail-inner">
            <section className="ds-detail-header" ref={this.headerRef}>
              <div className={"row"}>
                <div className={"col-3 ds-detail-thumbnail-container"}>
                  <div className={"ds-detail-thumbnail"}>
                    <img
                      src={thumbnailImagePath}
                      alt={this.isAdviser(mode) ? company : title}
                    />
                  </div>
                </div>
                <div className={"col-8"}>
                  <h1>{this.isAdviser(mode) ? company : title}</h1>
                  {this.isAdviser(mode) ? (
                    <p className="gray">{title}</p>
                  ) : (
                    <p className={"ds-detail-industry"}>
                      {industries.join(" ")}
                    </p>
                  )}
                </div>
              </div>
              {this.isSellBuy(mode) ? (
                <ul className={"ds-detail-values"}>
                  <li>
                    {socLabel}
                    {socLabel2}&ensp;
                    <span dangerouslySetInnerHTML={{ __html: socValue }} />
                  </li>
                  <li>
                    {profitLabel}
                    {profitLabel2}&ensp;
                    <span dangerouslySetInnerHTML={{ __html: profitValue }} />
                  </li>
                  <li>
                    {dobLabel}&ensp;
                    <span dangerouslySetInnerHTML={{ __html: dobValue }} />
                  </li>
                </ul>
              ) : null}
              <ul className={"ds-detail-tags"}>
                {this.isAdviser(mode)
                  ? industries.map((industry) => (
                      <li key={`industry-${industry}`}>
                        <label className={"badge badge-outline"}>
                          {industry}
                        </label>
                      </li>
                    ))
                  : null}
                {this.isSellBuy(mode) ? (
                  <li>
                    <label className={"badge  badge-outline"}>{status}</label>
                  </li>
                ) : null}
                {areas.map((area) => (
                  <li key={`area-${area}`}>
                    <label className={"badge  badge-outline"}>{area}</label>
                  </li>
                ))}
              </ul>
              <div className={"row"}>
                <div className={"col-12 pub-page-link-button-container"}>
                  <a
                    href={url}
                    target={hasProfile ? null : "_blank"}
                    className={
                      "btn btn-orange fs-15 form-control pub-page-link-button"
                    }
                    onClick={this.sendGcEvent}
                  >
                    掲載元へ
                  </a>
                </div>
                {/*<div className={"col-7"}>*/}
                {/*    <a href={url} target={"_blank"}*/}
                {/*       className={"fs-13 favorite-link"}*/}
                {/*       data-remodal-target={isLogged ? null : "modal-to_free_plan"}*/}
                {/*       href={isLogged ? null : "#modal-to_free_plan"}*/}
                {/*       onClick={isLogged ? this.favoriteItem(matchingDealUid) : null}*/}
                {/*    >お気に入りへ登録</a>*/}
                {/*</div>*/}
              </div>
            </section>
            <section className="ds-detail-body" ref={this.bodyRef}>
              <pre
                ref={this.preRef}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </section>
            {this.isSellBuy(mode) ? (
              <section className="ds-detail-footer" ref={this.footerRef}>
                <div className={"title"}>相談できるアドバイザーを探す</div>
                <ul className={"advisers row"}>
                  {recommendAdvisers.map((adviser) => (
                    <li className={"col-3"} key={adviser.id}>
                      <a href={`/profile/${adviser.id}`}>
                        <img
                          src={adviser.image_face_url}
                          alt={"M&Aアドバイザー"}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </section>
            ) : null}
          </section>
        </article>
      </div>
    );
  }

  /**
   * お気に入り登録する
   * @param matchingDealUid
   */
  favoriteItem(matchingDealUid) {
    RootStore.dispatch(
      DealSearchAction.favoriteItem.request({ matchingDealUid })
    );
  }

  /**
   * 売り・買い案件の場合
   */
  isSellBuy(mode) {
    return this.isSell(mode) || this.isBuy(mode);
  }

  /**
   * 売り案件の場合
   */
  isSell(mode) {
    return mode === "sell";
  }

  /**
   * 買い案件の場合
   */
  isBuy(mode) {
    return mode === "buy";
  }

  /**
   * アドバイザーの場合
   */
  isAdviser(mode) {
    return mode === "adviser";
  }

  /**
   * GCにイベントを送信
   */
  sendGcEvent() {
    const {
      industries,
      areas,
      profitSuffix,
      socSuffix,
      dobSuffix,
    } = this.props.dealSearch;
    const { mode } = this.props;

    switch (mode) {
      case "sell":
        industries.forEach((industry) => sendGcEvent("dimension3", industry));
        areas.forEach((area) => sendGcEvent("dimension4", area));
        sendGcEvent("metric1", socSuffix);
        sendGcEvent("metric2", profitSuffix);
        sendGcEvent("metric3", dobSuffix);
        break;
      case "buy":
        industries.forEach((industry) => sendGcEvent("dimension7", industry));
        areas.forEach((area) => sendGcEvent("dimension8", area));
        sendGcEvent("metric4", socSuffix);
        sendGcEvent("metric5", profitSuffix);
        sendGcEvent("metric6", dobSuffix);
        break;
      case "adviser":
        break;
      default:
        throw new Error(`unknown mode type: ${mode}`);
    }
  }
}

DealSearchSplitView.propTypes = dealSearchFeedItemPropTypes;

const mapStateToProps = createStructuredSelector({
  dealSearch: dealSearchShowSplitSelector,
  currentUser: currentUserSelector,
});

const mapDispatchToProps = {
  dealSearchShowSplitView: DealSearchAction.dealSearchShowSplitView,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealSearchSplitView);
