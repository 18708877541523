import React from "react"
import * as PropTypes from "prop-types"

import {Provider} from "react-redux";
import searchStore from "../../stores/searchStore"

const store = searchStore();

class SearchAppProvider extends React.Component {
    render() {
        return <Provider store={store}>
            {this.props.children}
        </Provider>;
    }
}

SearchAppProvider.propTypes = {
    children: PropTypes.node,
};

export default SearchAppProvider;

