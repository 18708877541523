import "../lib/common";
import "../lib/search";
import "core-js/stable";
import "regenerator-runtime/runtime";
import WebpackerReact from "webpacker-react";
import {
  DealSearchDetailForm,
  DealSearchFeedItemContainer,
  DealSearchDetailFormContainer,
  DealSearchSplitViewContainer,
  DealSearchFormCollapseButton,
  DealSearchJapanAreaMap,
  AreaTable,
  DealSearchSelectSearchTypeModal,
} from "../components/search";
import { DEVELOPMENT } from "../constants";

// noinspection JSUnresolvedFunction
WebpackerReact.setup({
  DealSearchDetailForm,
  DealSearchFeedItemContainer,
  DealSearchSplitViewContainer,
  DealSearchDetailFormContainer,
  DealSearchFormCollapseButton,
  DealSearchJapanAreaMap,
  AreaTable,
  DealSearchSelectSearchTypeModal,
});

if (process.env.NODE_ENV === DEVELOPMENT) {
  console.log("--- loaded search");
}
