"use strict"; // noinspection JSFunctionExpressionToArrowFunction

import { triggerButton } from "../maker/triggers";

$(document).ready(() => {
  const MEDIUM_DEVICE_SIZE = 767;
  /**
   * SplitViewの可変化
   */
  const splitViewScrollAction = function splitViewScrollAction() {
    const scrollRemainingSize =
      $(document.body).height() - $(window).height() - $(this).scrollTop();
    const headerHeight = 178;

    if ($(this).scrollTop() > headerHeight) {
      $("#ds-detail-container").addClass("fixed").css({
        height: "calc(100% - 20px)",
      }); // deal_searches.scss と同じ
    } else {
      $("#ds-detail-container")
        .removeClass("fixed")
        .css({
          height: $(window).height() - headerHeight - 22 + $(this).scrollTop(),
        });
    }

    const scrollFooterHeight = 45;

    if (scrollRemainingSize < scrollFooterHeight) {
      $("#ds-detail-container")
        .addClass("last-reached")
        .css({
          top: "auto",
          bottom: 67 - scrollRemainingSize,
        });
    } else {
      $("#ds-detail-container").removeClass("last-reached").css({
        top: 10,
        bottom: "auto",
      });
    }
  };
  /**
   * SplitView内の要素の調整
   * @type {number}
   */
  let footerOffset = 0;
  let documentBodyHeight = 0;

  const linkFooterScrollAction = function linkFooterScrollAction() {
    const scrollRemainingSize =
      documentBodyHeight - $(window).height() - $(this).scrollTop();
    const $pubPageContainer = $("#pub-page-container");

    if ($pubPageContainer.length) {
      if (scrollRemainingSize > footerOffset) {
        $pubPageContainer.addClass("fixed");
      } else {
        $pubPageContainer.removeClass("fixed");
        const offset = $($pubPageContainer).offset();
        footerOffset =
          $(document.body).height() -
          $($pubPageContainer).height() -
          40 -
          (offset ? offset.top : 0);
        documentBodyHeight = $(document.body).height();
      }
    }
  }; // noinspection JSFunctionExpressionToArrowFunction

  /**
   * イベントトリガー
   */
  const triggers = {
    "#search-index .trigger--add_report_deal": function searchIndexTriggerAdd_report_deal(
      elem,
      event
    ) {
      // お気に入り
      triggerButton($(elem), "add", "search", "report_deal", "お気に入り", {
        route: "search",
      });
    },
    "#search-index .trigger--remove_report_deal": function searchIndexTriggerRemove_report_deal(
      elem,
      event
    ) {
      // お気に入り解除
      triggerButton(
        $(elem),
        "remove",
        "search",
        "report_deal",
        "お気に入りへ登録",
        {
          route: "search",
        }
      );
    },
    "#deal-search-area-select-container": function dealSearchAreaSelectContainer(
      elem,
      event
    ) {
      // 地域選択を行った（セレクトボックス）
      if ($(window).width() <= MEDIUM_DEVICE_SIZE) {
        var formType = DealSearchScreenDetailFormAction.FormType.Area;
        RootStore.dispatch(
          DealSearchScreenDetailFormAction.open({
            formType: formType,
          })
        );
      }
    },
    "#deal-search-industry-container": function dealSearchIndustryContainer(
      elem,
      event
    ) {
      // 業種選択を行った
      if ($(window).width() <= MEDIUM_DEVICE_SIZE) {
        var formType = DealSearchScreenDetailFormAction.FormType.Industry;
        RootStore.dispatch(
          DealSearchScreenDetailFormAction.open({
            formType: formType,
          })
        );
      }
    },
    "#deal-search-budget-container": function dealSearchBudgetContainer(
      elem,
      event
    ) {
      // 予算・希望額選択を行った
      if ($(window).width() <= MEDIUM_DEVICE_SIZE) {
        const formType = DealSearchScreenDetailFormAction.FormType.Budget;
        RootStore.dispatch(
          DealSearchScreenDetailFormAction.open({
            formType: formType,
          })
        );
      }
    },
    ".trigger--link-access": function triggerLinkAccess(elem, event) {
      // パーマリンクから掲載元へアクセス
      const data = $("#ds-permalink-data").data();

      if (data) {
        switch (data.position) {
          case "sell":
            sendGcEvent("metric1", data.socSuffix);
            sendGcEvent("metric2", data.profitSuffix);
            sendGcEvent("metric3", data.dobSuffix);
            break;

          case "buy":
            sendGcEvent("metric4", data.socSuffix);
            sendGcEvent("metric5", data.profitSuffix);
            sendGcEvent("metric6", data.dobSuffix);
            break;

          case "adviser":
          default:
            // noinspection JSStringConcatenationToES6Template
            throw new Error("unknown mode type: " + data.position);
        }
      }
    },
    ".trigger--deal-search-select-industry-search-type": function triggerDealSearchSelectIndustrySearchType(
      elem,
      event
    ) {
      event.preventDefault();
      const industry = elem.getAttribute("name"); // noinspection ES6ShorthandObjectProperty

      window.showDealSearchSelectSearchTypeModal({
        industry: industry,
      });
      return false;
    },
    ".trigger--deal-search-select-area-search-type": function triggerDealSearchSelectAreaSearchType(
      elem,
      event
    ) {
      event.preventDefault();
      const area = elem.getAttribute("name"); // noinspection ES6ShorthandObjectProperty

      window.showDealSearchSelectSearchTypeModal({
        area: area,
      });
      return false;
    },
    ".trigger--download-ma-document": function triggerDealSearchDownloadMaDocument() {
      const date = new Date();
      date.setTime(date.getTime() + 1000 * 60 * 60 * 9);
      sendGcEvent("dimension10", date.toISOString().replace(/T.*/, ""));
    },
  };

  /**
   * デリゲート定義
   */
  const elementKeys = Object.keys(triggers);
  const elementToMaps = elementKeys
    .map((key) => $(key).map((_, elem) => [elem, key]))
    .reduce(
      (accumulator, currentValue) => accumulator.concat(currentValue),
      []
    );
  $(document).on("click", elementKeys.join(", "), function (event) {
    const elem = this;
    elementToMaps.forEach(func); // noinspection NestedFunctionJS

    function func(element) {
      if (element[0] === elem) {
        triggers[element[1]](this, event);
      }
    }
  });
  /**
   * スクロールイベント
   */

  if ($(window).width() > MEDIUM_DEVICE_SIZE) {
    // PC Only
    $(window).scroll(splitViewScrollAction);
    splitViewScrollAction();
  } else {
    // Mobile Only
    if (location.href.indexOf("item")) {
      $(window).scroll(linkFooterScrollAction);
      linkFooterScrollAction();
      setTimeout(linkFooterScrollAction, 0);
    }
  }
});
