import React from "react"
import * as PropTypes from "prop-types"
import SearchAppProvider from "../appProviders/SearchAppProvider";
import DealSearchFeedItem from "./DealSearchFeedItem";
import {dealSearchFeedItemPropTypes} from "../../propTypes/dealSearchFeedItemPropTypes";

class DealSearchFeedItemContainer extends React.Component {
  render() {
    return <SearchAppProvider>
      <DealSearchFeedItem {...this.props} />
    </SearchAppProvider>;
  }
}

DealSearchFeedItemContainer.propTypes = dealSearchFeedItemPropTypes;

export default DealSearchFeedItemContainer;

