import * as PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import { Spring } from "react-spring/renderprops";
import is from "is_js";

class DealSearchFormCollapseButton extends React.Component {
  DURATION = 100;
  OTHER_SEARCH_FIELD_CONTAINER_HEIGHT = 161;
  OTHER_SEARCH_FIELD_CONTAINER_HEIGHT__DIABLE_PRICE = 100;

  state = {
    isOpen: false,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    if (is.mobile()) {
      if (this.props.defaultIsOpen) {
        this.toggle();
        this.state.isOpen = this.props.defaultIsOpen;
      }
    }
  }

  render() {
    const { isOpen } = this.state;
    const { collapseImage } = this.props;

    return (
      <React.Fragment>
        <Spring
          config={{ duration: this.DURATION }}
          from={{ value: 0 }}
          to={{ value: isOpen ? this.getButtonPosition() : 0 }}
        >
          {(props) => (
            <div
              className={"other-search-condition"}
              style={{ right: props.value }}
            >
              <a className={"link"} href={"#"} onClick={this.toggle}>
                {props.value >= this.getButtonPosition() ? (
                  <div>
                    <img className={"collapse-image"} src={collapseImage} />
                  </div>
                ) : null}
                {props.value >= this.getButtonPosition()
                  ? "折りたたむ"
                  : "その他の条件"}
              </a>
            </div>
          )}
        </Spring>
        <img
          src={collapseImage}
          width={1}
          height={1}
          style={{ opacity: 0.1 }}
        />
      </React.Fragment>
    );
  }

  getButtonPosition() {
    return ($(window).width() - 150) / 2;
  }

  toggle() {
    const { disablePrice } = this.props;
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      const $elm = $("#other-search-condition-fields");
      $elm.animate({ height: 0 }, this.DURATION, () => $elm.hide());
    } else {
      this.setState({ isOpen: true });
      $("#other-search-condition-fields")
        .show()
        .animate(
          {
            height: disablePrice
              ? this.OTHER_SEARCH_FIELD_CONTAINER_HEIGHT__DIABLE_PRICE
              : this.OTHER_SEARCH_FIELD_CONTAINER_HEIGHT,
          },
          this.DURATION
        );
    }
  }
}

DealSearchFormCollapseButton.propTypes = {
  q: PropTypes.object,
  collapseImage: PropTypes.string,
  defaultIsOpen: PropTypes.bool,
  disablePrice: PropTypes.bool,
};

export default hot(module)(DealSearchFormCollapseButton);
