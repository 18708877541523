import React from "react";

export const DEAL_SEARCH_SHOW_SPLIT_VIEW = "DEAL_SEARCH_SHOW_SPLIT_VIEW";
export const DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY = "DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY";

export const DEAL_SEARCH_FETCH_DETAIL_REQUEST = "DEAL_SEARCH_FETCH_DETAIL_REQUEST";
export const DEAL_SEARCH_FETCH_DETAIL_FAILURE = "DEAL_SEARCH_FETCH_DETAIL_FAILURE";
export const DEAL_SEARCH_FETCH_DETAIL_SUCCESS = "DEAL_SEARCH_FETCH_DETAIL_SUCCESS";

export const DEAL_SEARCH_UPDATE_CURRENT_ITEM = "DEAL_SEARCH_UPDATE_CURRENT_ITEM";

export const DEAL_SEARCH_FAVORITE_ITEM_REQUEST = "DEAL_SEARCH_FAVORITE_ITEM_REQUEST";
export const DEAL_SEARCH_FAVORITE_ITEM_FAILURE = "DEAL_SEARCH_FAVORITE_ITEM_FAILURE";
export const DEAL_SEARCH_FAVORITE_ITEM_SUCCESS = "DEAL_SEARCH_FAVORITE_ITEM_SUCCESS";


const dealSearchShowSplitView = props => {
    const {
        company,
        title,
        url,
        matchingDealUid,
        industries,
        socLabel,
        socLabel2,
        profitLabel,
        profitLabel2,
        dobLabel,
        profitValue,
        socValue,
        dobValue,
        thumbnailImagePath,
        status,
        areas,
        business,
        profitSuffix,
        socSuffix,
        dobSuffix,
        hasProfile,
    } = props;
    return {
        type: DEAL_SEARCH_SHOW_SPLIT_VIEW,
        company,
        title,
        url,
        matchingDealUid,
        industries,
        socLabel,
        socLabel2,
        profitLabel,
        profitLabel2,
        dobLabel,
        profitValue,
        socValue,
        dobValue,
        thumbnailImagePath,
        status,
        areas,
        business,
        profitSuffix,
        socSuffix,
        dobSuffix,
        hasProfile,
    }
};

const dealSearchChangeSplitViewBody = props => {
    const {body} = props;
    return {
        type: DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY,
        body
    }
};

const dealSearchDetailRequest = props => {
    const {matchingDealUid, title} = props;
    return {
        type: DEAL_SEARCH_FETCH_DETAIL_REQUEST,
        matchingDealUid,
        title
    }
};

const dealSearchDetailFailure = props => {
    const {matchingDealUid} = props;
    return {
        type: DEAL_SEARCH_FETCH_DETAIL_FAILURE,
        matchingDealUid
    }
};

const dealSearchDetailSuccess = props => {
    const {body} = props;
    return {
        type: DEAL_SEARCH_FETCH_DETAIL_SUCCESS,
        body
    }
};

const dealSearchUpdateCurrentItem = props => {
    const {matchingDealUid} = props;
    return {
        type: DEAL_SEARCH_UPDATE_CURRENT_ITEM,
        matchingDealUid
    }
};

const dealSearchFavoriteItemRequest = props => {
    const {matchingDealUid} = props;
    return {
        type: DEAL_SEARCH_FAVORITE_ITEM_REQUEST,
        matchingDealUid
    }
};

const dealSearchFavoriteItemSuccess = props => {
    const {matchingDealUid} = props;
    return {
        type: DEAL_SEARCH_FAVORITE_ITEM_SUCCESS
    }
};

const dealSearchFavoriteItemFailure = props => {
    const {matchingDealUid} = props;
    return {
        type: DEAL_SEARCH_FAVORITE_ITEM_FAILURE
    }
};

export const DealSearchAction = {
    dealSearchShowSplitView,
    dealSearchChangeSplitViewBody,
    fetchDetail: {
        request: dealSearchDetailRequest,
        failure: dealSearchDetailFailure,
        success: dealSearchDetailSuccess
    },
    favoriteItem: {
        request: dealSearchFavoriteItemRequest,
        failure: dealSearchFavoriteItemFailure,
        success: dealSearchFavoriteItemSuccess
    },
    dealSearchUpdateCurrentItem
};


