import {
  DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY,
  DEAL_SEARCH_SHOW_SPLIT_VIEW,
  DEAL_SEARCH_UPDATE_CURRENT_ITEM
} from "../../actions/searchActions/DealSearchAction";

function dealSearchReducer(state = {}, action) {
  let dealSearchShowSplitView;
  switch (action.type) {
    case DEAL_SEARCH_SHOW_SPLIT_VIEW:
      dealSearchShowSplitView = {
        ...state.dealSearchShowSplitView,
        company: action.company,
        title: action.title,
        url: action.url,
        matchingDealUid: action.matchingDealUid,
        industries: action.industries,
        socLabel: action.socLabel,
        socLabel2: action.socLabel2,
        profitLabel: action.profitLabel,
        profitLabel2: action.profitLabel2,
        dobLabel: action.dobLabel,
        profitValue: action.profitValue,
        socValue: action.socValue,
        dobValue: action.dobValue,
        thumbnailImagePath: action.thumbnailImagePath,
        status: action.status,
        areas: action.areas,
        business: action.business,
        numberOfEmployees: action.numberOfEmployees,
        numberOfUsers: action.numberOfUsers,
        foundingYear: action.foundingYear,
        pv: action.pv,
        publicationAt: action.publicationAt,
        mode: action.mode,
        profitSuffix: action.profitSuffix,
        socSuffix: action.socSuffix,
        dobSuffix: action.dobSuffix,
        hasProfile: action.hasProfile,
        catchCopy: action.catchCopy,
        imageFace: action.imageFace,
        imageCompany: action.imageCompany,
        services: action.services,
        noFaceImagePath: action.noFaceImagePath,
      };
      return {...state, dealSearchShowSplitView};
    case DEAL_SEARCH_CHANGE_SPLIT_VIEW_BODY:
      dealSearchShowSplitView = {
        ...state.dealSearchShowSplitView,
        body: action.body,
      };
      return {...state, dealSearchShowSplitView};
    case DEAL_SEARCH_UPDATE_CURRENT_ITEM:
      return {...state, currentItem: action.matchingDealUid};
    default:
      return state;
  }
}

export default dealSearchReducer;
