import React from "react"
import * as PropTypes from "prop-types"
import SearchAppProvider from "../appProviders/SearchAppProvider";
import DealSearchSplitView from "./DealSearchSplitView";
import {dealSearchFeedItemPropTypes} from "../../propTypes/dealSearchFeedItemPropTypes";

class DealSearchSplitViewContainer extends React.Component {
  render() {
    return <SearchAppProvider>
      <DealSearchSplitView {...this.props} />
    </SearchAppProvider>;
  }
}

DealSearchSplitViewContainer.propTypes = dealSearchFeedItemPropTypes;

export default DealSearchSplitViewContainer;

