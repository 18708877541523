import Axios from "axios-observable";
import {ApiEndpoint} from "../utils/ApiEndpoint";
import {titleForUrl} from "../utils/titleForUrl";

export const MatchingDealApi = {
    showDetail: ({matchingDealUid, title}) => Axios.get(
        `${ApiEndpoint.searchMode()}/item/${titleForUrl(title)}/${matchingDealUid}.json`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            is_detail: true
        })
};
