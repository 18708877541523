import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import dealSearchReducer from "../reducers/search/dealSearchReducer";
// import {apiReducer, middleWare} from "redux-rails";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { dealSearchEpic } from "../epics/dealSearchEpic";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import currentUserReducer from "../reducers/currentUserReducer";
import { CurrentUserAction } from "../actions/CurrentUserAction";
import { dealSearchScreenDetailFormReducer } from "../reducers/search";
import { DealSearchScreenDetailFormAction } from "../actions/searchActions";
import { isIE } from "../utils";

let logger;
if (!isIE()) {
  logger = createLogger();
}

// キーはReducerと合わせる
const initialState = {
  dealSearch: {
    dealSearchShowSplitView: {
      company: "",
      title: "",
      url: "",
      matchingDealUid: null,
      body: "",
      industries: [],
      socLabel: "",
      socLabel2: "",
      profitLabel: "",
      profitLabel2: "",
      dobLabel: "",
      profitValue: "",
      socValue: "",
      dobValue: "",
      thumbnailImagePath: "",
      status: "",
      areas: [],
      business: "",
      numberOfEmployees: "",
      numberOfUsers: "",
      foundingYear: "",
      pv: "",
      publicationAt: "",
      mode: "",
      profitSuffix: 0,
      socSuffix: 0,
      dobSuffix: 0,
      hasProfile: false,
      catchCopy: "",
      imageFace: "",
      imageCompany: "",
      services: [],
      noFaceImagePath: "",
    },
    currentItem: null,
  },
  currentUser: {
    isLogged: false,
  },
  dealSearchScreens: {
    detailForm: {
      isOpen: false,
      formType: null,
      selectedAreas: [],
      selectedIndustries: [],
      selectedBudgets: [],
    },
  },
};

// キーはinitialStateと合わせる
const rootReducer = combineReducers({
  dealSearch: dealSearchReducer,
  currentUser: currentUserReducer,
  dealSearchScreens: combineReducers({
    detailForm: dealSearchScreenDetailFormReducer,
  }),
});

const epicMiddleware = createEpicMiddleware();

let reduxDevToolExtension = null;
// noinspection EmptyCatchBlockJS,UnusedCatchParameterJS
try {
  // noinspection JSUnresolvedFunction,JSUnresolvedVariable
  reduxDevToolExtension =
    __REDUX_DEVTOOLS_EXTENSION__ && __REDUX_DEVTOOLS_EXTENSION__();
} catch (e) {}

const DEVELOPMENT = "development";

// composeの順番は必ず thunkは上、logger と reduxDevToolExtension は下にする
export const RootStore = createStore(
  rootReducer,
  initialState,
  compose.apply(
    this,
    [
      applyMiddleware(thunk),
      applyMiddleware(epicMiddleware),
      process.env.NODE_ENV === DEVELOPMENT && logger
        ? applyMiddleware(logger)
        : null,
      process.env.NODE_ENV === DEVELOPMENT ? reduxDevToolExtension : null,
    ].filter((middleware) => !!middleware)
  )
);

const rootEpic = combineEpics(dealSearchEpic);

epicMiddleware.run(rootEpic);

const searchStore = () => RootStore;
export default searchStore;

try {
  setTimeout(() => {
    // webpack外で使用
    window.RootStore = RootStore;
    window.DealSearchScreenDetailFormAction = DealSearchScreenDetailFormAction;

    // ログインチェック
    RootStore.dispatch(CurrentUserAction.currentUserCheckLogin());
  }, 0);
} catch (e) {
  console.log("server side", e);
}

console.log('--- use searchStore');
