import {
    DEAL_SEARCH_SCREEN_DETAIL_FORM_CLOSE,
    DEAL_SEARCH_SCREEN_DETAIL_FORM_OPEN,
    DEAL_SEARCH_SELECT_AREA_ADD,
    DEAL_SEARCH_SELECT_AREA_REMOVE,
    DEAL_SEARCH_SELECT_AREA_REMOVE_ALL,
    DEAL_SEARCH_SELECT_BUDGET_ADD,
    DEAL_SEARCH_SELECT_BUDGET_REMOVE,
    DEAL_SEARCH_SELECT_BUDGET_REMOVE_ALL,
    DEAL_SEARCH_SELECT_INDUSTRY_ADD,
    DEAL_SEARCH_SELECT_INDUSTRY_REMOVE,
    DEAL_SEARCH_SELECT_INDUSTRY_REMOVE_ALL
} from "../../../actions/searchActions/DealSearchScreenDetailFormAction";

function dealSearchScreenDetailFormReducer(state = {}, action) {
    switch (action.type) {
        case DEAL_SEARCH_SCREEN_DETAIL_FORM_OPEN:
            const {formType} = action;
            return {...state, isOpen: true, formType};
        case DEAL_SEARCH_SCREEN_DETAIL_FORM_CLOSE:
            return {...state, isOpen: false};
        case DEAL_SEARCH_SELECT_AREA_ADD:
            return {...state, selectedAreas: [...state.selectedAreas, action.area]};
        case DEAL_SEARCH_SELECT_AREA_REMOVE:
            const selectedAreas = state.selectedAreas.filter(area => area !== action.area);
            return {...state, selectedAreas};
        case DEAL_SEARCH_SELECT_AREA_REMOVE_ALL:
            return {...state, selectedAreas: []};
        case DEAL_SEARCH_SELECT_INDUSTRY_ADD:
            return {...state, selectedIndustries: [...state.selectedIndustries, action.industry]};
        case DEAL_SEARCH_SELECT_INDUSTRY_REMOVE:
            const selectedIndustries = state.selectedIndustries.filter(industry => industry !== action.industry);
            return {...state, selectedIndustries};
        case DEAL_SEARCH_SELECT_INDUSTRY_REMOVE_ALL:
            return {...state, selectedIndustries: []};
        case DEAL_SEARCH_SELECT_BUDGET_ADD:
            return {...state, selectedBudgets: [...state.selectedBudgets, action.budget ? Number(action.budget) : ""]};
        case DEAL_SEARCH_SELECT_BUDGET_REMOVE:
            const selectedBudgets = state.selectedBudgets.filter(budget => budget !== action.budget);
            return {...state, selectedBudgets};
        case DEAL_SEARCH_SELECT_BUDGET_REMOVE_ALL:
            return {...state, selectedBudgets: []};
        default:
            return state;
    }
}

export default dealSearchScreenDetailFormReducer;
