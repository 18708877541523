import React from "react"
import {hot} from 'react-hot-loader'
import * as PropTypes from "prop-types"

/**
 * エリアの表から検索
 */
class AreaTable extends React.Component {

    constructor(props) {
        super(props);
        this.onClickPrefecture = this.onClickPrefecture.bind(this);
    }

    render() {
        const {
            title,
            items,
            endpointPrefix,
            endpointSuffix,
            top,
            left,
            onClick
        } = this.props;

        const splittedItems = this.splitArray(items, 2);
        const lastRowIndex = splittedItems.length - 1;
        const lastItemIndex = splittedItems[lastRowIndex].length - 1;
        const isSingle = lastRowIndex === 0 && lastItemIndex === 0;

        return <div className="react-component__area-table" style={{top, left}}>
            <div><h4 className="react-component__area-table__label">{title}</h4></div>
            {
                splittedItems.map((row, rowIndex) =>
                    <div key={`${title}-row-${rowIndex}`} className="row">
                        {
                            row.map((item, itemIndex) => {
                                    const onClickPrefecture = onClick ? e => {
                                        this.onClickPrefecture(e, item);
                                        e.preventDefault();
                                    } : null;
                                    return <a
                                        key={`${title}-row-${itemIndex}`}
                                        href={`${endpointPrefix}${encodeURIComponent(item)}${endpointSuffix}`}
                                        className={`${isSingle ? "col-12 single" : "col-6"} react-component__area-table__item`}
                                        onClick={onClickPrefecture}
                                    >
                                        <div className="react-component__area-table__item__inner">{item}</div>
                                    </a>;
                                }
                            )
                        }
                        {
                            lastRowIndex === rowIndex && lastItemIndex === 0 && !isSingle ? <div
                                className="col-6 react-component__area-table__item no-select">
                                <div className="react-component__area-table__item__inner"/>
                            </div> : null
                        }
                    </div>
                )
            }

        </div>;
    }

    /**
     * 分割
     * @param array
     * @param n
     * @returns {*}
     */
    splitArray(array, n) {
        return array.reduce((a, c, i) => i % n === 0 ? [...a, [c]] : [...a.slice(0, -1), [...a[a.length - 1], c]], []);
    }

    /**
     * 都道府県をクリック
     * @param event
     * @param item
     * @returns {boolean}
     */
    onClickPrefecture(event, item) {
        event.preventDefault();
        const {
            onClick
        } = this.props;
        onClick(event, item);
        return false;
    }

}

AreaTable.propTypes = {
    title: PropTypes.string.isRequired,
    endpointPrefix: PropTypes.string.isRequired,
    endpointSuffix: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    items: PropTypes.array.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
};

export default hot(module)(AreaTable);
