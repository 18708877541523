import React from "react"
import * as PropTypes from "prop-types"
import SearchAppProvider from "../appProviders/SearchAppProvider";
import DealSearchDetailForm from "./DealSearchDetailForm";
import {hot} from "react-hot-loader";

class DealSearchDetailFormContainer extends React.Component {
    render() {
        return <SearchAppProvider>
            <DealSearchDetailForm {...this.props} />
        </SearchAppProvider>;
    }
}

DealSearchDetailFormContainer.propTypes = {
    areas: PropTypes.object,
    industries: PropTypes.object,
    budgetRanges: PropTypes.array,
    mode: PropTypes.string,
    defaultProps: PropTypes.object,

};

export default DealSearchDetailFormContainer;
