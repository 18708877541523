$(() =>
  $(document)
    .on("change", "[name='q[region_eq]'].trigger--change-submit", function (
      event
    ) {
      event.preventDefault();
      const region = event.target.value;
      document.querySelector("[name='q[prefecture_eq]']").value = "";
      $("#q_matching_areas_area_eq_*").prop("checked", false);
      document.getElementById(
        `q_matching_areas_area_eq_${region}`
      ).checked = true;
      $(this).closest("form").submit();
    })
    .on("change", "[name='q[prefecture_eq]'].trigger--change-submit", function (
      event
    ) {
      event.preventDefault();
      const prefecture = event.target.value;
      $("#q_matching_areas_area_eq_*").prop("checked", false);
      document.getElementById(
        `q_matching_areas_area_eq_${prefecture}`
      ).checked = true;
      $(this).closest("form").submit();
    })
    .on(
      "change",
      "[name='q[matching_areas_area_eq]'].trigger--change-submit",
      function (event) {
        event.preventDefault();
        const area = event.target.value;

        const categories = JSON.parse(
          document.getElementById("data--categories").innerHTML
        );
        const regions = Object.keys(categories);
        const prefectures = Object.values(categories).reduce(
          (acc, prefectures) => acc.concat(prefectures),
          []
        );
        const isRegion = regions.indexOf(area) !== -1;
        const isPrefecture = prefectures.indexOf(area) !== -1;
        if (isRegion) {
          // 地方
          document.getElementById("q_region_eq").value = area;
          document.getElementById("q_prefecture_eq").value = "";
        } else if (isPrefecture) {
          // 都道府県
          document.getElementById("q_prefecture_eq").value = area;
        } else {
          // 全て
          document.getElementById("q_region_eq").value = "";
          document.getElementById("q_prefecture_eq").value = "";
        }
        $(this).closest("form").submit();
      }
    )
    .on("change", ".trigger--change-submit", function () {
      $(this).closest("form").submit();
    })
);
