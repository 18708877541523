import React from "react";
import { connect } from "react-redux";
import { DealSearchScreenDetailFormAction } from "../../actions/searchActions";
import { Spring } from "react-spring/renderprops";
import { dealSearchScreenDetailFormSelector } from "../../selectors/search";
import { hot } from "react-hot-loader";
import * as PropTypes from "prop-types";

class DealSearchDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.renderInner = this.renderInner.bind(this);
    this.selectArea = this.selectArea.bind(this);
    this.ok = this.ok.bind(this);
    this.isSelectedAreaAny = this.isSelectedAreaAny.bind(this);
    this.renderArea = this.renderArea.bind(this);
    this.renderIndustry = this.renderIndustry.bind(this);
    this.renderBudget = this.renderBudget.bind(this);
  }

  /**
   * セレクタの変数変更監視
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const { formType } = this.props;
      switch (formType) {
        case DealSearchScreenDetailFormAction.FormType.Area:
          const {
            defaultProps: { area },
          } = this.props;
          this.selectArea(area);
          break;
        case DealSearchScreenDetailFormAction.FormType.Industry:
          const {
            defaultProps: { industry },
          } = this.props;
          this.selectIndustry(industry);
          break;
        case DealSearchScreenDetailFormAction.FormType.Budget:
          const {
            defaultProps: { price },
          } = this.props;
          this.selectBudget(price);
          break;
        default:
          break;
      }
    }
  }

  render() {
    return (
      <Spring
        config={{ duration: 300 }}
        from={{ value: $(window).height() }}
        to={{ value: this.props.isOpen ? 0 : $(window).height() }}
      >
        {(props) => (
          <div
            className={"deal-search-detail-form-container"}
            style={{
              top: props.value,
              display: this.props.isOpen ? "block" : "none",
            }}
          >
            {this.renderInner(props)}
          </div>
        )}
      </Spring>
    );
  }

  /**
   * 中身を描画
   * @param props
   * @returns {*}
   */
  renderInner(props) {
    const { formType } = this.props;
    switch (formType) {
      case DealSearchScreenDetailFormAction.FormType.Area:
        return this.renderArea();
      case DealSearchScreenDetailFormAction.FormType.Industry:
        return this.renderIndustry();
      case DealSearchScreenDetailFormAction.FormType.Budget:
        return this.renderBudget();
      default:
        return <div />;
    }
  }

  /**
   * 決定ボタンを押した
   */
  ok() {
    const { formType, mode } = this.props;
    const $form = (() => {
      switch (mode) {
        case "adviser":
          return $("form#professional_search");
        default:
          return $("form#matching_deal_search");
      }
    })();

    this.props.screenClose();

    switch (formType) {
      case DealSearchScreenDetailFormAction.FormType.Area:
        const { areas, selectedAreas } = this.props;
        const selectedArea = selectedAreas[0];

        const regions = Object.keys(areas);
        const prefectures = Object.values(areas).reduce(
          (acc, prefectures) => acc.concat(prefectures),
          []
        );

        const isRegion = regions.indexOf(selectedArea) !== -1;
        const isPrefecture = prefectures.indexOf(selectedArea) !== -1;

        $("[name='q[region_eq]']").removeAttr("name");
        $("[name='q[prefecture_eq]']").removeAttr("name");
        $("[name='q[matching_areas_area_eq]']").remove();

        if (selectedArea) {
          if (isRegion) {
            $(
              `<input type="hidden" name="q[region_eq]" value="${selectedArea}" />`
            ).appendTo($form);
          } else if (isPrefecture) {
            const region = Object.keys(areas)
              .map((key) => [key, areas[key]])
              .filter((area) => area[1].indexOf(selectedArea) !== -1)[0][0];
            $(
              `<input type="hidden" name="q[region_eq]" value="${region}" />`
            ).appendTo($form);
            $(
              `<input type="hidden" name="q[prefecture_eq]" value="${selectedArea}" />`
            ).appendTo($form);
          }
          $(
            `<input type="hidden" name="q[matching_areas_area_eq]" value="${selectedArea}" />`
          ).appendTo($form);
        } else {
          $(
            `<input type="hidden" name="q[matching_areas_area_eq]" value="" />`
          ).appendTo($form);
        }
        break;
      case DealSearchScreenDetailFormAction.FormType.Industry:
        const { selectedIndustries } = this.props;
        const selectedIndustry = selectedIndustries[0];
        $("[name='q[industry_eq]']").removeAttr("name");
        $("[name='q[matching_industries_industry_eq]']").remove();
        if (selectedIndustry) {
          $(
            `<input type="hidden" name="q[matching_industries_industry_eq]" value="${selectedIndustry}" />`
          ).appendTo($form);
        } else {
          $(
            `<input type="hidden" name="q[matching_industries_industry_eq]" value="" />`
          ).appendTo($form);
        }
        break;
      case DealSearchScreenDetailFormAction.FormType.Budget:
        const { selectedBudgets } = this.props;
        const selectedBudget = selectedBudgets[0];
        $("[name='q[price_prefix_gteq]']").remove();
        if (selectedBudget) {
          $(
            `<input type="hidden" name="q[price_prefix_gteq]" value="${selectedBudget}" />`
          ).appendTo($form);
        } else {
          $(
            `<input type="hidden" name="q[price_prefix_gteq]" value="" />`
          ).appendTo($form);
        }
        break;
      default:
        throw new Error("No form type specified");
    }
    $form.submit();
  }

  /**
   * キャンセルボタンを押した
   */
  cancel() {
    this.props.screenClose();
  }

  /**
   * エリアを選択した
   * @param area 地域または都道府県
   */
  selectArea(area) {
    this.props.removeAllSelectArea();
    this.props.addSelectArea({ area });
  }

  /**
   * エリアが選択されているか
   */
  isSelectedArea(area) {
    const { selectedAreas } = this.props;
    return selectedAreas.indexOf(area) !== -1;
  }

  /**
   * 何か一つでもエリアが選択されている
   */
  isSelectedAreaAny() {
    const { selectedAreas } = this.props;
    return !!selectedAreas[0];
  }

  /**
   * 業種を選択した
   * @param industry 業種
   */
  selectIndustry(industry) {
    this.props.removeAllSelectIndustry();
    this.props.addSelectIndustry({ industry });
  }

  /**
   * 業種が選択されているか
   */
  isSelectedIndustry(industry) {
    const { selectedIndustries } = this.props;
    return selectedIndustries.indexOf(industry) !== -1;
  }

  /**
   * 何か一つでも業種が選択されている
   */
  isSelectedIndustryAny() {
    const { selectedIndustries } = this.props;
    return !!selectedIndustries[0];
  }

  /**
   * 何か一つでも希望額または予算が選択されている
   */
  isSelectedBudgetAny() {
    const { selectedBudgets } = this.props;
    return !!selectedBudgets[0];
  }

  /**
   * 業種を選択した
   * @param budget 業種
   */
  selectBudget(budget) {
    this.props.removeAllSelectBudget();
    this.props.addSelectBudget({ budget });
  }

  /**
   * 希望額または予算が選択されているか
   */
  isSelectedBudget(budget) {
    const { selectedBudgets } = this.props;
    return selectedBudgets.indexOf(budget) !== -1;
  }

  /**
   * コンテンツ領域の高さを取得
   */
  getContentHeight() {
    return (
      $(window).height() -
        $(".header-section").height() -
        $(".footer-section").height() || 0
    );
  }

  /**
   * 地域選択を描画
   */
  renderArea() {
    const { areas, removeAllSelectArea } = this.props;
    const prefectures = Object.keys(areas);

    return (
      <article>
        <section className={"header-section"}>
          <h1>地域選択</h1>
        </section>
        <section
          className={"content-section"}
          style={{ height: this.getContentHeight() }}
        >
          <div className="form-group">
            <ul>
              <li className={"prefecture-item"}>
                <label
                  className={`fs-11 m-0 form-radio-label ${
                    this.isSelectedAreaAny() ? "" : "selected"
                  }`}
                  onClick={removeAllSelectArea}
                >
                  すべて
                </label>
              </li>
              {prefectures.map((prefecture) => {
                const selectArea = this.selectArea.bind(this, prefecture);
                return (
                  <li
                    key={`prefecture-${prefecture}`}
                    className={"prefecture-item"}
                  >
                    <label
                      className={`fs-11 m-0 form-radio-label ${
                        this.isSelectedArea(prefecture) ? "selected" : ""
                      }`}
                      onClick={selectArea}
                    >
                      {prefecture}
                    </label>
                    <div className="form-group">
                      <ul>
                        {areas[prefecture].map((area) => {
                          const selectArea = this.selectArea.bind(this, area);
                          return (
                            <li key={`area-${area}`} className={"area-item"}>
                              <label
                                className={`fs-11 m-0 form-radio-label ${
                                  this.isSelectedArea(area) ? "selected" : ""
                                }`}
                                onClick={selectArea}
                              >
                                {area}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className={"footer-section container"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"button cancel left"} onClick={this.cancel}>
                キャンセル
              </div>
            </div>
            <div className={"col-6"}>
              <div className={"button ok right"} onClick={this.ok}>
                決定
              </div>
            </div>
          </div>
        </section>
      </article>
    );
  }

  /**
   * 業種選択を描画
   */
  renderIndustry() {
    const { industries, removeAllSelectIndustry } = this.props;
    const industryCategories = Object.keys(industries);
    return (
      <article>
        <section className={"header-section"}>
          <h1>業種選択</h1>
        </section>
        <section
          className={"content-section"}
          style={{ height: this.getContentHeight() }}
        >
          <div className="form-group">
            <ul>
              <li className={"industry-category-item"}>
                <label
                  className={`fs-11 m-0 form-radio-label ${
                    this.isSelectedIndustryAny() ? "" : "selected"
                  }`}
                  onClick={removeAllSelectIndustry}
                >
                  すべて
                </label>
              </li>
              {industryCategories.map((industry) => {
                const selectIndustry = this.selectIndustry.bind(this, industry);
                return (
                  <li key={`industry-${industry}`} className={"industry-item"}>
                    <label
                      className={`fs-11 m-0 form-radio-label ${
                        this.isSelectedIndustry(industry) ? "selected" : ""
                      }`}
                      onClick={selectIndustry}
                    >
                      {industry}
                    </label>
                    <div className="form-group">
                      <ul>
                        {industries[industry].map((industry) => {
                          const selectIndustry = this.selectIndustry.bind(
                            this,
                            industry
                          );
                          return (
                            <li
                              key={`industry-${industry}`}
                              className={"industry-item"}
                            >
                              <label
                                className={`fs-11 m-0 form-radio-label ${
                                  this.isSelectedIndustry(industry)
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={selectIndustry}
                              >
                                {industry}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className={"footer-section container"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"button cancel left"} onClick={this.cancel}>
                キャンセル
              </div>
            </div>
            <div className={"col-6"}>
              <div className={"button ok right"} onClick={this.ok}>
                決定
              </div>
            </div>
          </div>
        </section>
      </article>
    );
  }

  /**
   * 希望額・予算を設定
   */
  renderBudget() {
    const SELL = "sell";
    const { removeAllSelectBudget, budgetRanges, mode } = this.props;

    return (
      <article>
        <section className={"header-section"}>
          <h1>{mode === SELL ? "希望額" : "予算"}選択</h1>
        </section>
        <section
          className={"content-section"}
          style={{ height: this.getContentHeight() }}
        >
          <div className="form-group">
            <ul>
              <li className={"budget-category-item"}>
                <label
                  className={`fs-11 m-0 form-radio-label ${
                    this.isSelectedBudgetAny() ? "" : "selected"
                  }`}
                  onClick={removeAllSelectBudget}
                >
                  すべて
                </label>
              </li>
              {budgetRanges.map((budgetRange) => {
                const key = budgetRange[0];
                const label = budgetRange[1];
                const selectBudget = this.selectBudget.bind(this, key);
                return (
                  <li key={`budget-${key}`} className={"budget-item"}>
                    <label
                      className={`fs-11 m-0 form-radio-label ${
                        this.isSelectedBudget(key) ? "selected" : ""
                      }`}
                      onClick={selectBudget}
                    >
                      {label}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <section className={"footer-section container"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"button cancel left"} onClick={this.cancel}>
                キャンセル
              </div>
            </div>
            <div className={"col-6"}>
              <div className={"button ok right"} onClick={this.ok}>
                決定
              </div>
            </div>
          </div>
        </section>
      </article>
    );
  }
}

DealSearchDetailForm.propTypes = {
  areas: PropTypes.object,
  industries: PropTypes.object,
  budgetRanges: PropTypes.array,
  mode: PropTypes.string,
  defaultProps: PropTypes.object,
};

const mapDispatchToProps = {
  addSelectArea: DealSearchScreenDetailFormAction.selectArea.add,
  removeSelectArea: DealSearchScreenDetailFormAction.selectArea.remove,
  removeAllSelectArea: DealSearchScreenDetailFormAction.selectArea.removeAll,
  addSelectIndustry: DealSearchScreenDetailFormAction.selectIndustry.add,
  removeSelectIndustry: DealSearchScreenDetailFormAction.selectIndustry.remove,
  removeAllSelectIndustry:
    DealSearchScreenDetailFormAction.selectIndustry.removeAll,
  addSelectBudget: DealSearchScreenDetailFormAction.selectBudget.add,
  removeSelectBudget: DealSearchScreenDetailFormAction.selectBudget.remove,
  removeAllSelectBudget:
    DealSearchScreenDetailFormAction.selectBudget.removeAll,
  screenClose: DealSearchScreenDetailFormAction.close,
};
export default hot(module)(
  connect(
    dealSearchScreenDetailFormSelector,
    mapDispatchToProps
  )(DealSearchDetailForm)
);
